/* Footer.css */
footer {
    background-color: #ff007f; /* Girls Lips Filler theme color */
    color: #fff; /* Light text on dark background */
}

footer h5 {
    color: #fff; /* Light color for headings */
    font-weight: bold;
}

footer a {
    color: #fff; /* Light color for links */
    text-decoration: none;
}

footer a:hover {
    color: #ff007f; /* Theme color on hover */
    text-decoration: underline;
}

.footer-logo {
    max-width: 150px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
}

@media (max-width: 768px) {
    .footer-logo {
        max-width: 120px; /* Adjust for smaller screens */
    }

    .footer-links {
        text-align: center;
    }

    .footer-links ul {
        padding: 0;
    }
}

@media (max-width: 576px) {
    .footer-logo {
        max-width: 100px; /* Further adjustment for extra small screens */
    }

    footer {
        padding: 2rem 1rem; /* Reduce padding for small screens */
    }
}
