/* General Reset and Base Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
  
html, body {
    height: 100%;
    width: 100%;
    font-family: 'Arial', sans-serif; /* Optional: You can change this to Bootstrap's default font */
}
  
#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Override Bootstrap styles if necessary */
a {
    text-decoration: none;
    color: inherit;
}

/* Additional custom styles (if needed) */
h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
    color: #333; /* You can customize colors if needed */
}
  
p {
    margin-bottom: 1rem;
    color: #666; /* You can customize colors if needed */
}
