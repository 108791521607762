/* Header Container */
header {
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
}

/* Navbar */
.navbar {
    padding: 0 1rem;
}

/* Navbar Brand */
.navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: #343a40;
    padding-right: 1rem;
}

/* Navbar Links */
.nav-link {
    color: #495057;
    padding: 0.5rem 1rem;
    font-weight: 500;
}

.nav-link:hover {
    color: #007bff;
}

/* Search Bar */
.form-control {
    border-radius: 20px;
    padding: 0.5rem 1rem;
    position: relative;
    z-index: 2; /* Ensure it stays on top of other elements */
}

.btn-outline-success {
    border-radius: 20px;
    padding: 0.5rem 1rem;
}

/* Suggestions List */
.suggestions-list {
    position: absolute;
    top: 100%; /* Positions it directly below the search bar */
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 0 0 0.25rem 0.25rem;
    border: 1px solid #dee2e6;
    border-top: none; /* Prevent double border at the top */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
    padding: 0.5rem 0;
}

.suggestions-list .list-group-item {
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.suggestions-list .list-group-item:hover {
    background-color: #f8f9fa;
}

/* Profile Menu */
.user-profile {
    position: relative;
    padding-left: 1rem;
}

.user-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #343a40;
}

.user-icon span {
    margin-left: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
}

.profile-menu {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 0;
    z-index: 1000;
}

.profile-menu a,
.profile-menu button {
    display: block;
    padding: 0.5rem 1rem;
    color: #343a40;
    text-align: left;
    font-size: 0.9rem;
    text-decoration: none;
}

.profile-menu a:hover,
.profile-menu button:hover {
    background-color: #f8f9fa;
}

/* Cart Icon */
.cart-icon {
    position: relative;
    padding-left: 1rem;
    color: #343a40;
}

.cart-count {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: #dc3545;
    color: #fff;
    border-radius: 50%;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
}

/* Media Queries for Responsive Design */

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 575.98px) {
    .navbar-brand {
        font-size: 1.25rem;
    }

    .nav-link {
        padding: 0.4rem 0.75rem;
    }

    .user-icon span {
        display: none; /* Hide the name to save space on smaller screens */
    }

    .form-control {
        font-size: 0.875rem; /* Smaller font size for search input */
    }

    .btn-outline-success {
        padding: 0.4rem 0.75rem;
        font-size: 0.875rem;
    }

    .suggestions-list {
        max-height: 150px; /* Reduce height for smaller screens */
    }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) {
    .navbar-brand {
        font-size: 1.5rem;
    }

    .nav-link {
        padding: 0.4rem 0.75rem;
    }

    .user-icon span {
        font-size: 0.9rem; /* Smaller font size */
    }

    .form-control {
        font-size: 1rem;
    }

    .btn-outline-success {
        padding: 0.4rem 1rem;
        font-size: 1rem;
    }

    .suggestions-list {
        max-height: 175px; /* Adjust height for medium screens */
    }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 991.98px) {
    .navbar-brand {
        font-size: 1.75rem;
    }

    .nav-link {
        padding: 0.5rem 1rem;
    }

    .user-icon span {
        font-size: 1rem;
    }

    .form-control {
        font-size: 1.125rem;
    }

    .btn-outline-success {
        padding: 0.5rem 1.25rem;
        font-size: 1.125rem;
    }

    .suggestions-list {
        max-height: 200px; /* Default height for larger screens */
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .navbar-brand {
        font-size: 2rem;
    }

    .nav-link {
        padding: 0.6rem 1.25rem;
    }

    .user-icon span {
        font-size: 1.125rem;
    }

    .form-control {
        font-size: 1.25rem;
    }

    .btn-outline-success {
        padding: 0.6rem 1.5rem;
        font-size: 1.25rem;
    }

    .suggestions-list {
        max-height: 250px; /* Increase height for very large screens */
    }
}
